// extracted by mini-css-extract-plugin
export var ArtistDescription = "JammieHolmes-module--ArtistDescription--aTZzu";
export var ArtistInfos = "JammieHolmes-module--ArtistInfos--NzNcJ";
export var ButtonWrapper = "JammieHolmes-module--ButtonWrapper --DCBOw";
export var CardWrapper = "JammieHolmes-module--CardWrapper--PHXv1";
export var CarrouselWrapper2 = "JammieHolmes-module--CarrouselWrapper2--gLj5-";
export var Citations = "JammieHolmes-module--Citations--MuMrw";
export var DescriptionWrapper = "JammieHolmes-module--DescriptionWrapper--n1CIA";
export var ImageWrapper = "JammieHolmes-module--ImageWrapper--nYyzB";
export var LinkWrapper = "JammieHolmes-module--LinkWrapper--2DeP1";
export var MobileProtrait = "JammieHolmes-module--MobileProtrait--adk-j";
export var More = "JammieHolmes-module--More--frV1+";
export var MoreButton = "JammieHolmes-module--MoreButton--ONpdH";
export var NameWrapper = "JammieHolmes-module--NameWrapper--20QyX";
export var PdpWrapper = "JammieHolmes-module--PdpWrapper--Vbizi";
export var PhotosWrapper = "JammieHolmes-module--PhotosWrapper--LfI2z";
export var ProfilWrapper = "JammieHolmes-module--ProfilWrapper--FLmQ6";
export var TitleWrapper = "JammieHolmes-module--TitleWrapper--DDxuQ";
export var Wrapper = "JammieHolmes-module--Wrapper--Maxl1";